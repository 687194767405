import jquery from "jquery";

window.$ = jquery;
window.jQuery = jquery;

window.jQuery(() => {
  // Do not use import.meta.env here, since this file is pre-bundled by Vite
  if (process.env.NODE_ENV === "development") {
    console.info(`jQuery: ${window.$.fn.jquery}`);
  }
});
